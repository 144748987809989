















import { Component, Prop, Vue } from "vue-property-decorator";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";

@Component({
  components: { SaveButton, AbortButton },
})
export default class GenerateStudentInvoiceModal extends Vue {
  public name = "GenerateStudentInvoiceModal";

  @Prop({ required: true, type: String })
  protected modalId!: string;

  protected ok(): void {
    this.$emit("ok");
  }
}
