






















import { Component, Prop, Vue } from "vue-property-decorator";
import AuthImage from "@/directives/AuthImage";
import instructorPlaceholder from "@/assets/teacherimgplaceholder.png";
import { PropType } from "vue";
import { UserTypeEnum } from "@/enums/UserTypeEnum";

@Component({
  directives: {
    AuthImage: new AuthImage(),
  },
})
export default class Avatar extends Vue {
  @Prop({ type: Number, default: 0 })
  public userId!: number;

  @Prop({ default: UserTypeEnum.STUDENT, type: Number as PropType<UserTypeEnum> })
  public userType!: number;

  public hasError = false;

  public get avatarUrl(): string {
    const URL = this.userType == UserTypeEnum.STUDENT ? "students/avatar?studentId=" : "users/avatar?userId=";
    return `${URL}${this.userId}`;
  }

  public handleError(event: Event): void {
    this.hasError = true;
    (event.target as HTMLImageElement).src = instructorPlaceholder;
    (event.target as HTMLImageElement).alt = "Profile";
  }

  protected get hasId(): boolean {
    return Boolean(this.userId);
  }
}
