<template>
  <div class="divider border-right mx-1"></div>
</template>

<script>
export default {
  name: "VerticalDivider",
};
</script>

<style scoped>
.divider {
  width: 1px;
  height: 36px;
}
</style>
