







import { Component, Vue, Prop } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";

@Component
export default class FileCheckButton extends Vue {
  public name = "FileCheckButton";

  @Prop({ default: () => uuidv4() })
  public id!: string;

  @Prop()
  public tooltipLabel: any;
}
