import { Component, Vue } from "vue-property-decorator";
import { IAutomaticPaymentStudent } from "@/interfaces/IAutomaticPaymentStudent";
import { namespace } from "vuex-class";
import _ from "lodash";
import { CLASSIC_PAY } from "@/constants/PaymentWorkflow";
import { IPaymentWorkflowFilter } from "@/interfaces/IPaymentWorkflowFilter";
import { formatStudentName } from "@/utils/NameUtil";

const AppModule = namespace("app");
const PaymentDocumentModule = namespace("payment-document");
const StudentEducationModule = namespace("student-education");
const ClassicPayModule = namespace("classic-pay");

@Component
export default class AutomaticPaymentMixin extends Vue {
  @AppModule.Action("setSelectedAutomaticPaymentStudent")
  protected setSelectedAutomaticPaymentStudent: any;

  @PaymentDocumentModule.Action("generateFinalInvoices")
  protected generateFinalInvoices: any;

  @PaymentDocumentModule.Getter("getSuccess")
  protected generateFinalInvoicesSuccess!: any;

  @StudentEducationModule.Action("changeFinishedEducationState")
  protected changeFinishedEducationState: any;

  @StudentEducationModule.Getter("getFinishedEducationStateSuccess")
  protected getFinishedEducationStateSuccess!: any;

  @StudentEducationModule.Getter("getFinishedEducationStateError")
  protected getFinishedEducationStateError!: any;

  @StudentEducationModule.Action("changeAutomaticPaymentState")
  protected changeAutomaticPaymentState: any;

  @StudentEducationModule.Getter("getAutomaticPaymentStateSuccess")
  protected getAutomaticPaymentStateSuccess!: any;

  @ClassicPayModule.Action("findAllStudentList")
  public paymentWorkflowsStudentListFindAll: any;

  protected visibleDocumentPreview = false;

  protected selectedResources: Array<any> = [];

  protected allowedResources: Array<any> = [];

  protected modalId = "generate-student-modal";
  protected documentId = "document-preview";

  protected unfinishedEdModal = "unfinishedEdModal";
  protected generateFinalInvoiceConfirmationModalId = "classic-pay-generate-final-invoice-confirmation-modal-id";

  protected filterModal = "classic-pay-filter-modal";

  public query: Record<any, any> | null = null;

  public paymentFilter: IPaymentWorkflowFilter = {} as IPaymentWorkflowFilter;
  public appliedFilter = false;

  private async onClickStudent(item: IAutomaticPaymentStudent, paymentType: number): Promise<void> {
    const { studentEducationId, licenseClass, student } = item;
    if (!studentEducationId) return;

    this.setSelectedAutomaticPaymentStudent({
      studentEducationId: String(studentEducationId),
      licenseClass,
      studentName: formatStudentName(student.firstName, student.lastName),
      paymentType,
    });

    await this.$router.push({
      name: "StudentDocumentsHistory",
    });
  }

  protected onSelectedResources(selectedEducations: Array<any>): void {
    this.selectedResources = selectedEducations;
  }

  protected async onGenerateFinalInvoice(): Promise<void> {
    if (!this.selectedResources.length || this.hasNotPermissionWrite) return;

    const isEveryFinishedEducationFalse = _.every(this.selectedResources, ["finishedEducation", false]);

    const isEveryEducationFinishedTrue = _.every(this.selectedResources, ["finishedEducation", true]);
    if (isEveryEducationFinishedTrue && this.selectedResources.length === 1) {
      await this.ok();
    } else if (isEveryFinishedEducationFalse) {
      this.$bvModal.show(this.unfinishedEdModal);
    } else if (!isEveryFinishedEducationFalse) {
      this.$bvModal.show(this.modalId);
    }
  }

  /**
   * Get the IDs for the selected resources.
   */
  protected get selectedResourceIds() {
    this.allowedResources = this.selectedResources.filter((allowedResource: any) => allowedResource.finalInvoiceDate === null);
    const resourcesIds = this.allowedResources.filter((selected: any) => selected.finishedEducation === true && selected.student);
    return _.map(resourcesIds, (resource) => resource.studentEducationId);
  }

  protected async changeFinishedEducationStateHandle(item: any): Promise<void> {
    if (!item.studentEducationId) {
      this.$toasted.error("Student Education ID is null");
      throw "Student Education ID is null";
    }

    await this.changeFinishedEducationState({
      params: {
        id: item.studentEducationId,
        finishedEducation: Boolean(item.finishedEducation),
      },
    });

    if (!this.getFinishedEducationStateSuccess) {
      item.finishedEducation = !item.finishedEducation;
    } else {
      this.$toasted.success(this.$t("messages.save_success").toString());
    }

    if (this.getFinishedEducationStateError) {
      console.log(this.getFinishedEducationStateError);
    }
  }

  protected async changeAutomaticPaymentStateHandle(item: any): Promise<void> {
    await this.changeAutomaticPaymentState({
      params: {
        id: item.studentEducationId,
        automaticPaymentEnabled: Boolean(item.automaticPaymentEnabled),
      },
    });

    if (!this.getAutomaticPaymentStateSuccess) {
      item.automaticPaymentEnabled = !item.automaticPaymentEnabled;
    } else {
      this.$toasted.success(this.$t("messages.save_success").toString());
    }
  }

  protected async onClickPaymentTrigger(): Promise<void> {
    await this.$router.push({ name: "AutomaticPaymentTriggers" });
  }

  protected async ok(): Promise<void> {
    if (this.selectedResourceIds.length === 0) {
      this.$toasted.error("Selected student already has generated invoice!");
      return;
    } else {
      this.$bvModal.show(this.generateFinalInvoiceConfirmationModalId);
    }
  }

  protected generateInvoicePreview() {
    this.$bvModal.show(this.documentId);
  }

  protected async generateFinalInvoice(): Promise<void> {
    this.$bvModal.hide(this.documentId);
    await this.generateFinalInvoices(this.selectedResourceIds);
    if (this.generateFinalInvoicesSuccess) {
      await this.paymentWorkflowsStudentListFindAll({
        ...this.query,
        paymentWorkflowId: CLASSIC_PAY,
      });
      this.$toasted.success(this.$t("messages.save_success").toString());
    }
  }

  protected cancelGenerateInvoice() {
    this.$bvModal.hide(this.documentId);
  }

  protected get hasNotPermissionWrite(): boolean {
    return !this.$can(this.MODULE_AUTOMATIC_PAYMENTS_WRITE);
  }

  protected openFilterModal() {
    this.$bvModal.show(this.filterModal);
  }

  public requestQuery(query: Record<any, any> | null): void {
    //
  }

  protected onFilter() {
    this.requestQuery(this.query);
    this.$bvModal.hide(this.filterModal);
  }
}
